<template>
  <div class="page">
    <div class="banner">
      <img class="img" src="@/static/become/peixun.png" />
    </div>
    <div class="content">
      <div class="view">
        <div class="title-view">
          <div class="left-icon"></div>
          课程详情
          <div class="left-icon"></div>
        </div>
        <div style="display: flex">
          <img class="image" :src="$host + info.image" />
          <div class="rightView">
            <div class="title">{{ info.title }}</div>
            <div style="margin-top: 10px;font-weight:bold">课程介绍: </div>
            <div v-html="info.description"></div>
          </div>
        </div>
        <div style="margin: 10px 0">
          <span class="weight">课程大纲：</span>{{ info.outline }}
        </div>
        <div style="margin: 10px 0">
          <span class="weight">适用人群：</span>{{ info.for_people }}
        </div>
        <div style="margin: 10px 0">
          <span class="weight">学习目标：</span>{{ info.learning_aims }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
    };
  },
  mounted() {
    // console.log(this.$route.params)
    if (this.$route.params) {
      this.info = this.$route.params;
    }
  },
};
</script>
<style scoped lang="scss">
.content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.page {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.view {
  width: 1200px;
  margin: 20px 0;
}

.title-view {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  height: 56px;
  color: #333;
  .left-icon {
    width: 120px;
    height: 5px;
    background: #fe6039;
    opacity: 0.75;
    border-radius: 2.5px;
    margin: 0 20px;
  }
}
.image {
  width: 480px;
  height: auto;
  margin-right: 40px;
}
.rightView {
  width: 680px;
  .title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    color: #333;
    font-weight: bold;
  }
}
.weight {
  font-weight: bold;
}
</style>
